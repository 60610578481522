import WorkflowTicketMixin from '@/mixins/workflowTicket.mixin'
import { mapMutations } from 'vuex'
import WorkflowTicketFormInput from '@/components/Workflow/WorkflowTicketForm/WorkflowTicketFormInput/WorkflowTicketFormInput.vue'
import Memo from '@/components/inputs/Memo/Memo.vue'
import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'

export default {
  props: {
    id: { type: String, default: null },
    name: { type: String, default: null },
    workflowTicket: { type: Object, default: () => {} },
    workflowTicketGraphType: { type: String, default: null },
    validateWorkflowTicketForm: { type: Boolean, default: false },
    isSecondToTheLastStep: { type: Boolean, default: false }
  },
  mixins: [WorkflowTicketMixin],
  components: {
    WorkflowTicketFormInput,
    Memo,
    FullScreenLoader
  },
  data() {
    return {
      loading: false,
      comment: '',
      formData: {},
      valid: true
    }
  },
  watch: {
    valid(val) {
      this.updateIsFormValid(val)
    }
  },
  methods: {
    approveOnSave(val) {
      this.$emit('approveOnSave', val)
    },
    rejectOnSave(val) {
      this.$emit('rejectOnSave', val)
    },
    updateIsFormValid(val) {
      this.$emit('updateIsFormValid', val)
    },
    updateWorkflowTicket(workflowTicket) {
      this.$emit('updateWorkflowTicket', workflowTicket)
    },
    async saveChangesAsync(action) {
      this.loading = true

      let input = {
        id: this.workflowTicket.id,
        memo: this.workflowTicket.memo
      }

      // add extra fields
      switch (this.currentType.singleQueryName) {
        case 'desktopDeployment':
          if (this.workflowTicket.extraFields['operatorComputer']) {
            input['operatorComputerId'] =
              this.workflowTicket.extraFields['operatorComputer'].id
          } else {
            input['operatorComputerId'] = null
          }
          if (this.workflowTicket.extraFields['operatorAssignee']) {
            input['operatorAssigneeId'] =
              this.workflowTicket.extraFields['operatorAssignee'].id
          } else {
            input['operatorAssigneeId'] = null
          }
          input['operatorCostCode'] =
            this.workflowTicket.extraFields['operatorCostCode'] || null
          input['isNewPc'] = this.workflowTicket.extraFields['isNewPc'] || null
          break
        case 'laptopDeployment':
          if (this.workflowTicket.extraFields['operatorComputer']) {
            input['operatorComputerId'] =
              this.workflowTicket.extraFields['operatorComputer'].id
          } else {
            input['operatorComputerId'] = null
          }
          if (this.workflowTicket.extraFields['operatorAssignee']) {
            input['operatorAssigneeId'] =
              this.workflowTicket.extraFields['operatorAssignee'].id
          } else {
            input['operatorAssigneeId'] = null
          }
          input['operatorCostCode'] =
            this.workflowTicket.extraFields['operatorCostCode'] || null
          break
        case 'pcRepair':
          if (this.workflowTicket.extraFields['operatorComputer']) {
            input['operatorComputerId'] =
              this.workflowTicket.extraFields['operatorComputer'].id
          } else {
            input['operatorComputerId'] = null
          }
          break
        case 'pcUserChange':
          if (this.workflowTicket.extraFields['operatorComputer']) {
            input['operatorComputerId'] =
              this.workflowTicket.extraFields['operatorComputer'].id
          } else {
            input['operatorComputerId'] = null
          }
          if (this.workflowTicket.extraFields['operatorAssignee']) {
            input['operatorAssigneeId'] =
              this.workflowTicket.extraFields['operatorAssignee'].id
          } else {
            input['operatorAssigneeId'] = null
          }
          break
        case 'pcOsRefresh':
          if (this.workflowTicket.extraFields['operatorComputer']) {
            input['operatorComputerId'] =
              this.workflowTicket.extraFields['operatorComputer'].id
          } else {
            input['operatorComputerId'] = null
          }
          break
        case 'pcReturn':
          if (this.workflowTicket.extraFields['operatorComputer']) {
            input['operatorComputerId'] =
              this.workflowTicket.extraFields['operatorComputer'].id
          } else {
            input['operatorComputerId'] = null
          }
          break
        case 'mobileDeployment':
          if (this.workflowTicket.extraFields['operatorMobile']) {
            let mobileInput = {
              name: this.workflowTicket.extraFields['operatorMobile'].name,
              serialNumber:
                this.workflowTicket.extraFields['operatorMobile'].serialNumber,
              id: this.workflowTicket.extraFields['operatorMobile'].id
            }
            input['operatorMobileId'] = mobileInput.id
          } else {
            input['operatorMobileId'] = null
          }
          if (this.workflowTicket.extraFields['operatorSim']) {
            let simInput = {
              name: this.workflowTicket.extraFields['operatorSim'].name,
              serialNumber:
                this.workflowTicket.extraFields['operatorSim'].serialNumber,
              id: this.workflowTicket.extraFields['operatorSim'].id
            }
            input['operatorSimId'] = simInput.id
          } else {
            input['operatorSimId'] = null
          }
          if (this.workflowTicket.extraFields['operatorAssignee']) {
            input['operatorAssigneeId'] =
              this.workflowTicket.extraFields['operatorAssignee'].id
          } else {
            input['operatorAssigneeId'] = null
          }
          break
        case 'mobileUserChange':
          if (this.workflowTicket.extraFields['operatorMobile']) {
            let mobileInput = {
              name: this.workflowTicket.extraFields['operatorMobile'].name,
              serialNumber:
                this.workflowTicket.extraFields['operatorMobile'].serialNumber,
              id: this.workflowTicket.extraFields['operatorMobile'].id
            }
            input['operatorMobileId'] = mobileInput.id
          } else {
            input['operatorMobileId'] = null
          }
          if (this.workflowTicket.extraFields['operatorNewSim']) {
            let simInput = {
              name: this.workflowTicket.extraFields['operatorNewSim'].name,
              serialNumber:
                this.workflowTicket.extraFields['operatorNewSim'].serialNumber,
              id: this.workflowTicket.extraFields['operatorNewSim'].id
            }
            input['operatorNewSimId'] = simInput.id
          } else {
            input['operatorNewSimId'] = null
          }
          break
        case 'mobileSimChange':
          if (this.workflowTicket.extraFields['operatorMobile']) {
            let mobileInput = {
              name: this.workflowTicket.extraFields['operatorMobile'].name,
              serialNumber:
                this.workflowTicket.extraFields['operatorMobile'].serialNumber,
              id: this.workflowTicket.extraFields['operatorMobile'].id
            }
            input['operatorMobileId'] = mobileInput.id
          } else {
            input['operatorMobileId'] = null
          }
          if (this.workflowTicket.extraFields['operatorNewSim']) {
            let simInput = {
              name: this.workflowTicket.extraFields['operatorNewSim'].name,
              serialNumber:
                this.workflowTicket.extraFields['operatorNewSim'].serialNumber,
              id: this.workflowTicket.extraFields['operatorNewSim'].id
            }
            input['operatorNewSimId'] = simInput.id
          } else {
            input['operatorNewSimId'] = null
          }
          break
        default:
          break
      }

      const allFields = ['id', 'memo', 'status']
        .concat(
          this.extraFields
            .filter((f) => !f.fields && !f.readOnly)
            .map((f) => f.name)
        )
        .concat(this.extraFields.filter((f) => f.fields && !f.readOnly))

      const fields = this.getFieldsGql(
        'read',
        this.currentType.graphTypeName,
        allFields
      )

      let singleQueryName = this.currentType.singleQueryName
      if (singleQueryName === 'pcAccessorie') {
        singleQueryName = 'pcAccessory'
      }

      // send by form type
      this.$apollo
        .mutate({
          // Query
          mutation: this.$gql`
            mutation ${this.currentType.update.mutationName}($input: ${this.currentType.update.inputType}) {
              ${this.currentType.update.mutationName}(input: $input) {
                ${singleQueryName} {
                  ${fields}
                }
              }
            }
          `,
          variables: { input }
        })
        .then(({ data }) => {
          if (data) {
            if (action === 'Approve') {
              this.approveOnSave(true)
            } else if (action === 'Reject') {
              this.rejectOnSave(true)
            } else {
              this.setAppSnackbar({
                text: 'Workflow ticket changes saved.',
                color: 'success'
              })
            }
          }

          this.loading = false
        })
        .catch((e) => {
          let msg = null
          if (action === 'Approve') {
            msg = 'Cannot approve workflow ticket.'
          } else if (action === 'Reject') {
            msg = 'Cannot reject workflow ticket.'
          }

          this.loading = false
          this.addError(
            null,
            `Failed to save changes. ${e.toString()}. ${msg}`,
            'form'
          )
        })
    },
    ...mapMutations(['setAppSnackbar'])
  },
  mounted() {
    if (this.validateWorkflowTicketForm) {
      this.$refs.workflowTicketForm.validate()
    }
  }
}
